<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'app',
  computed: {
    ...mapGetters([
      'isMobile',
      'isTest',
      'user',
      'windowWidth'
    ])
  },
  created () {
    if (window.location.hostname.includes('family.more.co.za')) {
      // this.setServerTest()
      this.setServerLive()
    } else if (window.location.hostname === 'localhost') {
      this.setServerLocal()
    } else {
      this.setServerTest()
    }
  },
  mounted () {
    this.getSizes()
    this.setIsMobile(this.$isMobile())
    this.setIsTablet(!this.isMobile && this.windowWidth <= 768)
    window.addEventListener('resize', () => {
      this.getSizes()
      this.setIsMobile(this.$isMobile())
      this.setIsTablet(!this.isMobile && this.windowWidth <= 768)
    })
  },
  methods: {
    ...mapActions([
      'setIsMobile',
      'setIsTablet',
      'setServerLive',
      'setServerLocal',
      'setServerTest',
      'setWindowHeight',
      'setWindowWidth'
    ]),
    getSizes () {
      const windowHeight = window.innerHeight
      const windowWidth = window.innerWidth
      this.setWindowHeight(windowHeight)
      this.setWindowWidth(windowWidth)
    }
  }
}
</script>

<style>
#app {
  font-family: Raleway;
  font-size: 20px;
  line-height: 34px;
  letter-spacing: 0.44px;
  color: var(--sand);
}
</style>
