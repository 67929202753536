import Vue from 'vue'
import axios from 'axios'

const state = {
  confirmations: null,
  countries: null,
  itineraries: null,
  profile: null,
  token: null,
  travelPlanner: null,
  user: null
}

const mutations = {
  clearUserState (state) {
    for (const k of Object.keys(state)) {
      Vue.set(state, k, null)
    }
  },
  setConfirmations (state, confirmations) {
    state.confirmations = confirmations
  },
  setCountries (state, countries) {
    state.countries = countries.items
  },
  setItineraries (state, itineraries) {
    state.itineraries = itineraries
  },
  setProfilePicture (state, filename) {
    state.profile.img = filename
  },
  setProfile (state, profile) {
    state.profile = profile
  },
  setToken (state, token) {
    state.token = token
  },
  setTravelPlanner (state, travelPlanner) {
    state.travelPlanner = travelPlanner
  },
  setUser (state, user) {
    state.user = user
  }
}

const actions = {
  validPassword ({ getters }, password) {
    return new Promise((resolve, reject) => {
      axios.get(`${getters.serverApi}password/valid/${password}`).then(res => {
        resolve(res.data)
      }, error => {
        console.log('fff', error.response)
        console.error(error)
        if (error.response && error.response.data && error.response.data.message) {
          reject(error.response.data.message)
        } else {
          reject(error)
        }
      })
    })
  },
  validateEmail ({ getters }, data) {
    return new Promise((resolve, reject) => {
      axios.put(`${getters.serverApi}user/verify/${data.email}/${data.code}`).then(res => {
        resolve(res.data)
      }, error => {
        console.error(error)
        if (error.response && error.response.data && error.response.data.message) {
          reject(error.response.data.message)
        } else {
          reject(error)
        }
      })
    })
  },
  async login ({ commit, getters }, user) {
    const { username, password } = user
    try {
      const { data } = await axios.post(getters.serverApi + 'login', { username, password })
      if (data.success) {
        data.user.expires = data.expires
        commit('setToken', data.token)
        commit('setUser', data.user)
        return Promise.resolve(data)
      } else {
        return Promise.reject(new Error('Something went wrong...'))
      }
    } catch (error) {
      if (error.response && error.response.status && error.response.status === 510 && error.response.data && error.response.data.message) {
        console.log('error.response.data.message', error.response.data.message)
        return Promise.reject(error.response.data.message)
      } else {
        return Promise.reject(new Error('Wrong username or password'))
      }
    }
  },
  passwordForgotten ({ getters }, data) {
    return new Promise((resolve, reject) => {
      axios.post(getters.serverApi + 'passwordforgotten', {
        username: data.username,
        email: data.email
      }).then(
        res => {
          const data = res.data
          resolve(data)
        },
        error => {
          if (error.response && error.response.data && error.response.data.message) {
            reject(error.response.data.message)
          } else {
            reject(error.message)
          }
        }
      )
    })
  },
  register ({ getters }, data) {
    return new Promise((resolve, reject) => {
      axios.post(getters.serverApi + 'user/guest', data).then(
        res => {
          const data = res.data
          resolve(data)
        },
        error => {
          if (error.response && error.response.data && error.response.data.message) {
            reject(error.response.data.message)
          } else {
            reject(error.message)
          }
        }
      )
    })
  },
  addProfilePicture ({ commit, getters }, formData) {
    return new Promise((resolve, reject) => {
      axios
        .post(getters.serverApi + 'guest/picture', formData, {
          headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'multipart/form-data' }
        })
        .then(
          res => {
            if (res.data.success) {
              commit('setProfilePicture', res.data.filename)
            }
            resolve(res.data)
          },
          error => {
            console.error(error)
            reject(error)
          }
        )
    })
  },
  getConfirmations ({ commit, getters }) {
    return new Promise((resolve, reject) => {
      axios.get(getters.serverApi + 'guest/confirmations').then(res => {
        commit('setConfirmations', res.data)
        resolve(true)
      }, error => {
        console.error(error)
        reject(error)
      })
    })
  },
  getCountries ({ commit, getters }) {
    return new Promise((resolve, reject) => {
      if (getters.countries) {
        resolve(true)
      } else {
        axios.get(getters.serverApi + 'countries').then(res => {
          commit('setCountries', res.data)
          resolve(true)
        }, error => {
          console.error(error)
          reject(error)
        })
      }
    })
  },
  getItineraries ({ commit, getters }) {
    return new Promise((resolve, reject) => {
      axios.get(getters.serverApi + 'guest/itins').then(res => {
        commit('setItineraries', res.data)
        resolve(true)
      }, error => {
        console.error(error)
        reject(error)
      })
    })
  },
  editProfile ({ getters }, details) {
    return new Promise((resolve, reject) => {
      axios.put(getters.serverApi + 'guest', { details }).then(
        res => {
          resolve(res.data.success)
        },
        error => {
          console.error(error)
          reject(error)
        }
      )
    })
  },
  getProfile ({ commit, getters }) {
    return new Promise((resolve, reject) => {
      axios.get(getters.serverApi + 'guest').then(
        res => {
          const data = res.data
          if (data.children) {
            data.children = data.children.map(c => ({ ...c, ...c.info }))
          }
          commit('setProfile', data)
          resolve(true)
        },
        error => {
          console.error(error)
          reject(error)
        }
      )
    })
  },
  getItineraryPdf ({ getters }, id) {
    axios.get(getters.serverApi + 'itinerary/pdf/' + id, {
      headers: {
        Accept: 'application/pdf'
      },
      responseType: 'blob'
    }).then(
      response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Itinerary.pdf')
        document.body.appendChild(link)
        link.click()
        setTimeout(function () {
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)
        }, 0)
      },
      error => {
        console.error(error)
      }
    )
  },
  getQuotePdf ({ getters }, id) {
    axios.get(getters.serverApi + 'quote/pdf/' + id, {
      headers: {
        Accept: 'application/pdf'
      },
      responseType: 'blob'
    }).then(
      response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Confirmation.pdf')
        document.body.appendChild(link)
        link.click()
        setTimeout(function () {
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)
        }, 0)
      },
      error => {
        console.error(error)
      }
    )
  },
  getTravelPlanner ({ commit, getters }) {
    return new Promise((resolve, reject) => {
      if (getters.travelPlanner) {
        resolve(getters.travelPlanner)
      } else {
        axios.get(getters.serverApi + 'travelplanner').then(res => {
          commit('setTravelPlanner', res.data)
          resolve(getters.travelPlanner)
        }, error => {
          console.error(error)
          reject(error)
        })
      }
    })
  },
  getTravelPlannerFromCode ({ commit, getters }, code) {
    return new Promise((resolve, reject) => {
      axios.get(getters.serverApi + 'guest/code/' + code + '/travelplanner').then(res => {
        commit('setTravelPlanner', res.data)
        resolve(getters.travelPlanner)
      }, error => {
        console.error(error)
        reject(error)
      })
    })
  },
  resetPassword ({ getters }, data) {
    return new Promise((resolve, reject) => {
      axios.post(getters.serverApi + 'passwordreset', {
        code: data.code,
        forced: data.forced,
        password: data.password
      }).then(
        res => {
          const data = res.data
          resolve(data)
        },
        error => {
          console.error(error)
          reject(error)
        }
      )
    })
  },
  savePPAcceptance ({ getters }, data) {
    return new Promise((resolve, reject) => {
      axios.post(getters.serverApi + 'guest/privacy', data).then(res => {
        resolve(true)
      }, error => {
        console.error(error)
        reject(error)
      })
    })
  },
  updatePassword ({ getters }, data) {
    return new Promise((resolve, reject) => {
      axios.put(getters.serverApi + 'passwordupdate', data).then(
        res => {
          resolve(res.data)
        },
        error => {
          console.error(error)
          reject(error)
        }
      )
    })
  },
  uploadDocument ({ getters }, formData) {
    return new Promise((resolve, reject) => {
      axios.post(getters.serverApi + 'guest/documents', formData, {
        headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'multipart/form-data' }
      }).then(
        res => {
          resolve(res.data)
        },
        error => {
          console.error(error)
          reject(error)
        }
      )
    })
  },
  verifyPasswordResetCode ({ getters }, { code, forced }) {
    return new Promise((resolve, reject) => {
      let ep = 'passwordreset/' + code
      if (forced) {
        ep += '/1'
      }
      axios.get(getters.serverApi + ep).then(
        res => {
          resolve(res.data)
        },
        error => {
          if (error && error.response && error.response.data) {
            reject(error.response.data)
          }
          reject(error)
        }
      )
    })
  },
  async logout ({ commit, getters }, redirect) {
    if (getters.user) {
      try {
        await axios.put(getters.serverApi + 'logout')
        commit('clearUserState')
        commit('clearEnquiryState')
        commit('clearPageHistory')
        return Promise.resolve(true)
      } catch (error) {
        console.error(error)
        commit('clearUserState')
        commit('clearEnquiryState')
        commit('clearPageHistory')
        return Promise.resolve(false)
      }
    } else {
      return Promise.resolve(false)
    }
  }
}

const getters = {
  confirmations: state => state.confirmations,
  countries: state => state.countries,
  itineraries: state => state.itineraries,
  profile: state => state.profile,
  token: state => state.token,
  travelPlanner: state => state.travelPlanner,
  user: state => state.user
}

export default {
  state,
  mutations,
  actions,
  getters
}
