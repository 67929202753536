// import Vue from 'vue'
import axios from 'axios'

const state = {
}

const mutations = {
}

const actions = {
  addMember ({ getters }, data) {
    return new Promise((resolve, reject) => {
      axios.post(getters.serverApi + 'member', data).then(() => {
        resolve(true)
      }, error => {
        console.error(error)
        reject(error)
      })
    })
  }
}

const getters = {
}

export default {
  state,
  mutations,
  actions,
  getters
}
