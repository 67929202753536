import Vue from 'vue'
import axios from 'axios'

const state = {
  categories: null,
  enquiries: null,
  properties: null,
  propertyTypes: null,
  quotes: null
}

const mutations = {
  clearEnquiryState (state) {
    for (const k of Object.keys(state)) {
      Vue.set(state, k, null)
    }
  },
  setCategories (state, categories) {
    state.categories = categories
  },
  setEnquiries (state, enquiries) {
    state.enquiries = state.enquiries || {}
    const ids = []
    for (const en of enquiries) {
      ids.push(en.id)
      state.enquiries[en.id] = { ...state.enquiries[en.id], ...en }
    }
    for (const en of Object.entries(state.enquiries)) {
      if (!ids.includes(en.id)) {
        delete en.id
      }
    }
  },
  setProperties (state, res) {
    const properties = res.items
    state.properties = properties
  },
  setPropertyTypes (state, propertyTypes) {
    state.propertyTypes = propertyTypes.items
  },
  setQuotes (state, data) {
    state.enquiries[data.enqId].quotes = data.quotes
  }
}

const actions = {
  createEnquiry ({ getters }, enqData) {
    return new Promise((resolve, reject) => {
      axios.post(getters.serverApi + 'enquiry', enqData).then(res => {
        if (res.data.success) {
          resolve(res.data.id)
        } else {
          resolve(false)
        }
      }, error => {
        console.error(error)
        reject(error)
      })
    })
  },
  getCategories ({ commit, getters }) {
    return new Promise((resolve, reject) => {
      axios.get(getters.serverApi + 'categories').then(
        res => {
          commit('setCategories', res.data.items)
          resolve(true)
        },
        error => {
          console.error(error)
          reject(error)
        }
      )
    })
  },
  getEnquiries ({ commit, getters }) {
    return new Promise((resolve, reject) => {
      axios.get(getters.serverApi + 'enquiries').then(
        res => {
          commit('setEnquiries', res.data)
          resolve(res.data)
        },
        error => {
          console.error(error)
          reject(error)
        }
      )
    })
  },
  getProperties ({ commit, getters }) {
    return new Promise((resolve, reject) => {
      if (getters.properties) {
        resolve(true)
      } else {
        axios.get(getters.serverApi + 'properties/dropdown').then(res => {
          commit('setProperties', res.data)
          resolve(true)
        }, error => {
          console.error(error)
          reject(error)
        })
      }
    })
  },
  getPropertyTypes ({ commit, getters }) {
    return new Promise((resolve, reject) => {
      if (!getters.propertyTypes) {
        axios.get(getters.serverApi + 'property/types').then(res => {
          commit('setPropertyTypes', res.data)
          resolve(getters.propertyTypes)
        }, error => {
          console.error(error)
          reject(error)
        })
      } else {
        resolve(getters.propertyTypes)
      }
    })
  },
  getQuotes ({ commit, getters }, enqId) {
    return new Promise((resolve, reject) => {
      axios.get(getters.serverApi + 'quotes/' + enqId).then(res => {
        const data = {
          enqId: enqId,
          quotes: res.data
        }
        commit('setQuotes', data)
        resolve(true)
      }, error => {
        console.error(error)
        reject(error)
      })
    })
  },
  setTravelInsuranceRequested ({ commit, getters }, payload) {
    return new Promise((resolve, reject) => {
      const enqId = payload.id
      axios.put(getters.serverApi + 'enquiry/' + enqId + '/travelinsurance', { requested: payload.requested }).then(
        res => {
          resolve(true)
        }, error => {
          console.error(error)
          reject(error)
        })
    })
  }
}

const getters = {
  categories: state => state.categories,
  enquiries: state => state.enquiries,
  properties: state => state.properties,
  propertyTypes: state => state.propertyTypes,
  quotes: state => state.quotes
}

export default {
  state,
  mutations,
  actions,
  getters
}
