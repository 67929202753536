import axios from 'axios'
import store from '../store'

export default function setup () {
  axios.interceptors.request.use(config => {
    if (!config.url.startsWith('http')) {
      config.url = `${store.getters.serverApi}${config.url}`
    }
    const token = store.getters.token
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    config.headers.Accept = 'application/json'
    config.headers['X-Platform'] = 'OGP'
    config.headers['X-Env'] = store.getters.isTest ? 'test' : 'live'
    return config
  }, err => {
    return Promise.reject(err)
  })
}
