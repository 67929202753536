import axios from 'axios'
import moment from 'moment'
// import Vue from 'vue'

const state = {
  appVersion: null,
  appVersionNum: null,
  isMobile: null,
  isTablet: null,
  isTest: null,
  newVersion: true,
  prevPage: null,
  pages: [],
  prevRoute: null,
  serverApi: null,
  serverMedia: null,
  windowHeight: null,
  windowWidth: null
}

const mutations = {
  clearPageHistory (state) {
    state.prevPage = null
    state.pages = []
  },
  setAppVersion (state, version) {
    state.newVersion = true
    state.appVersion = version.date
    state.appVersionNum = '1.0.' + version.id.toString()
  },
  setIsMobile (state, isit) {
    state.isMobile = isit
  },
  setIsTablet (state, isit) {
    state.isTablet = isit
  },
  setNoNewVersion (state) {
    state.newVersion = false
  },
  setPageHistory (state, page) {
    state.pages = state.pages || []
    if (state.pages && state.pages.length > 1) {
      const prevPage = state.pages[state.pages.length - 1]
      if (prevPage.page === page.page && prevPage.subMenu === page.subMenu) {
        return
      }
    }
    state.pages.push({ ...page, t: Date.now() })
    if (state.pages && state.pages.length > 1) {
      state.prevPage = state.pages[state.pages.length - 2]
    } else {
      state.prevPage = null
    }
  },
  setPrevRoute (state, route) {
    state.prevRoute = route
  },
  popPageHistory (state) {
    state.pages.pop()
    if (state.pages && state.pages.length > 0) {
      state.prevPage = state.pages[state.pages.length - 1]
    } else {
      state.prevPage = null
    }
  },
  setServer (state, type) {
    state.isTest = type !== 'live'
    if (type === 'live') {
      state.serverApi = 'https://api.mptconsol.co.za/'
      state.serverMedia = 'https://mpt-media.s3.us-east-2.amazonaws.com/'
    } else if (type === 'test') {
      state.serverApi = 'https://api.mpttesting.co.za/'
      state.serverMedia = 'https://mpt-media.s3.us-east-2.amazonaws.com/'
    } else { // local
      state.serverApi = 'https://api.mpttesting.co.za/'
      // state.serverApi = 'http://mptconsolapi/'
      state.serverMedia = 'https://mpt-media.s3.us-east-2.amazonaws.com/'
    }
  },
  setWindowHeight (state, h) {
    state.windowHeight = h
  },
  setWindowWidth (state, w) {
    state.windowWidth = w
  }
}

const actions = {
  checkNewAppVersion ({ commit, getters }) {
    return new Promise((resolve, reject) => {
      axios.get(getters.serverApi + 'version').then(res => {
        const version = res.data.date
        if (!moment(getters.appVersion).isValid() || (moment(version).isValid() && moment(version).isAfter(moment(getters.appVersion)))) {
          commit('setAppVersion', res.data)
          resolve(true)
        } else {
          resolve(false)
        }
      }, error => {
        console.error(error)
        reject(error)
      })
    })
  },
  clearPageHistory ({ commit }) {
    commit('clearPageHistory')
  },
  setIsMobile  ({ commit }, isit) {
    commit('setIsMobile', isit)
  },
  setIsTablet  ({ commit }, isit) {
    commit('setIsTablet', isit)
  },
  setNoNewVersion ({ commit }) {
    commit('setNoNewVersion', true)
  },
  setPageHistory ({ commit }, page) {
    commit('setPageHistory', page)
  },
  setPrevRoute ({ commit }, route) {
    commit('setPrevRoute', route)
  },
  popPageHistory ({ commit }) {
    commit('popPageHistory')
  },
  setServerLive ({ commit }) {
    commit('setServer', 'live')
  },
  setServerLocal ({ commit }) {
    commit('setServer', 'local')
  },
  setServerTest ({ commit }) {
    commit('setServer', 'test')
  },
  setWindowHeight ({ commit }, h) {
    commit('setWindowHeight', h)
  },
  setWindowWidth ({ commit }, w) {
    commit('setWindowWidth', w)
  }
}

const getters = {
  appVersion: state => state.appVersion,
  appVersionNum: state => state.appVersionNum,
  isMobile: state => state.isMobile,
  isTablet: state => state.isTablet,
  isTest: state => state.isTest,
  newVersion: state => state.newVersion,
  pagesHistory: state => state.pages,
  prevRoute: state => state.prevRoute,
  serverApi: state => state.serverApi,
  serverMedia: state => state.serverMedia,
  windowHeight: state => state.windowHeight,
  windowWidth: state => state.windowWidth
}

export default {
  state,
  mutations,
  actions,
  getters
}
