import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import moment from 'moment'
import store from '@/store'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: { requiresAuth: true }
    },
    // {
    //   path: '/admin',
    //   name: 'HomeAdmin',
    //   component: () => import('./views/HomeAdmin.vue')
    // },
    // // {
    //   path: '/',
    //   name: 'Landing',
    //   component: () => import('./views/Landing.vue')
    // },
    {
      path: '/login/verify/:email/:code',
      name: 'LoginV',
      component: () => import('./pages/Login.vue'),
      meta: { requiresAuth: false }
    },
    {
      path: '/login/:code?',
      name: 'Login',
      component: () => import('./pages/Login.vue'),
      meta: { requiresAuth: false }
    },
    {
      path: '/optout/:code',
      name: 'OptOut',
      component: () => import('./views/OptOut.vue'),
      meta: { requiresAuth: false }
    },
    {
      path: '/profile',
      name: 'Profile',
      component: () => import('./views/Profile.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/questionnaire/:code',
      name: 'QuestionnaireShortlink',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/QuestionnaireShortlink.vue'),
      meta: { requiresAuth: false }
    // },
    // {
    //   path: '/questionnaire',
    //   name: 'Questionnaire',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ './views/Questionnaire.vue'),
    //   meta: { requiresAuth: true }
    }
  ]
})

router.beforeEach(async (to, from, next) => {
/*
  if (to.path === '/login') {
    window.location.reload(true)
  }
*/
  store.dispatch('setPrevRoute', from.name)

  const user = store.getters.user

  if (to.matched.some(record => record.meta.requiresAuth) && !user) {
    await store.dispatch('logout', to.fullPath)
    next({ path: '/login' })
    // next({ path: '/login', query: { redirect: to.fullPath } })
  } else {
    if (to.matched.some(record => !record.meta.requiresAuth)) {
      next()
    } else {
      const res = await store.dispatch('checkNewAppVersion')
      if (res) {
        await store.dispatch('logout', to.fullPath)
        next({ path: '/login' })
        // next({ path: '/login', query: { redirect: to.fullPath } })
      } else {
        if (user && moment().isAfter(moment.unix(user.expires))) {
          await store.dispatch('logout', to.fullPath)
          next({ path: '/login' })
        // next({ path: '/login', query: { redirect: to.fullPath } })
        } else {
          next()
        }
      }
    }
  }
})

export default router
