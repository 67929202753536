import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import interceptorsSetup from './helpers/interceptors'
// import './registerServiceWorker'
import ImageUploader from 'vue-image-upload-resize'
import VTooltip from 'v-tooltip'
import VueMobileDetection from 'vue-mobile-detection'
import factories from '@/api'
import axios from 'axios'
import VueAxios from 'vue-axios'

interceptorsSetup()
Vue.use(ImageUploader)
Vue.use(VueAxios, axios)
Vue.use(VTooltip)
Vue.use(VueMobileDetection)
Vue.$api = factories
Vue.prototype.$api = factories

Vue.config.productionTip = false
require('./assets/css/bootstrap.css')
require('./assets/css/custom2020.css')

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
