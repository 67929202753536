<template>
  <div>
    <!--MOBILE-->
    <div v-if="isMobile || isTablet">
      <div class="row m-0">
        <div class="col-12 p-0">
          <mobile-header
            :showMenu="showMobileMenu"
            :theme="theme"
            @toggleMenu="showMobileMenu = !showMobileMenu"
          />
        </div>
        <div class="col-12 p-0 mobile_page_content">
          <mobile-mainpage
            ref="mobileMainpage"
            :showMenu="showMobileMenu"
            :theme="theme"
            :showModalTAndC="showModalTAndC"
            @toggleMenu="showMobileMenu = !showMobileMenu"
            @toggleModalTAndC="toggleModalTAndC"
          />
        </div>
        <div class="col-12 p-0">
          <mobile-footer @openModalTAndC="toggleModalTAndC"></mobile-footer>
        </div>
      </div>
    </div>
    <!--MOBILE-->
    <!--DESKTOP-->
    <div v-else>
      <custom-sidebar :user="user" :theme="theme" :selectedMenuItem="selectedMenuItem" :showSubMenu="showSubMenu" @selectMenu="selectMenu" @activeSubMenu="activeSubMenu"></custom-sidebar>
      <!-- <custom-mainpage :theme="theme" :currentPage="activePage" :currentSubPage="currentSubMenu" :isSubmenu="isSubmenu"></custom-mainpage> -->
      <custom-mainpage :theme="theme" :selectedMenuItem="selectedMenuItem" :prevPage="prevPage" :currentPage="activePage" :currentSubPage="currentSubMenu" :showSubMenu="showSubMenu" @goBack="goBack" @changeSubPage="changeSubPage" @changeActivePage="changeActivePage"></custom-mainpage>
    </div>
    <!--END DESKTOP-->
    <!--BEGIN TRAVEL INSURANCE-->
    <!-- <two-buttons-popup v-for="e in enquiriesTravelInsurance" :key="e.id" :id="e.id" :title="popupTravelInsuranceTitle + ' [enquiry ' + e.id + ']'" :text="popupTravelInsuranceText" :btn1="popupTravelInsuranceBtn1" :btn2="popupTravelInsuranceBtn2" @close="travelInsuranceRequest"></two-buttons-popup> -->
    <!--END TRAVEL INSURANCE-->
    <two-buttons-popup v-if="showPreferencesPrompt" title="" :text="preferencesPromptText" btn1="Go to My Preferences" btn2="Close" @close="goToPreferences"></two-buttons-popup>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { getMenuItem, getSubItem } from '../helpers/menu'

export default {
  name: 'Home',
  components: {
    'custom-sidebar': () => import('@/components/SideBar.vue'),
    'custom-mainpage': () => import('@/components/MainPage.vue'),
    'mobile-mainpage': () => import('@/components/mobile/MainPage.vue'),
    'mobile-header': () => import('@/components/mobile/Header.vue'),
    'mobile-footer': () => import('@/components/mobile/Footer.vue'),
    'two-buttons-popup': () => import('@/components/modals/TwoButtons.vue')
  },
  data () {
    return {
      user: {
        name: null,
        surname: null
      },
      theme: 'bush',
      showMobileMenu: false,
      previousPage: '',
      selectedMenuItem: null,
      activePage: '',
      showModalTAndC: false,
      showSubMenu: false,
      parentMenuValue: null,
      currentSubMenu: '',
      previousSubMenu: '',
      preferencesPromptText: 'We understand that each of our guests is unique. We&rsquo;d love to learn a bit more about you so that we can craft a personalised experience at our Lodges and Hotels.<br/><br/>We&rsquo;ve compiled a few questions in the &lsquo;My Preferences&rsquo; section of your personal guest profile, and would so appreciate a moment of your time so that we can get to know (and serve) you better.',
      // popupTravelInsuranceTitle: 'Travel Insurance',
      // popupTravelInsuranceText: 'As flexible as MORE Family Concierge is being in relation to future travel, we highly recommend that guests take out comprehensive travel insurance; including "cancel for any reason" (where still available) to ensure any funds lost can be recovered.',
      // popupTravelInsuranceBtn1: 'Accept',
      // popupTravelInsuranceBtn2: 'Reject',
      // enquiriesTravelInsurance: [],
      showPreferencesPrompt: null,
      goingBack: false
    }
  },
  computed: {
    ...mapGetters([
      'enquiries',
      'profile',
      'firstLogin',
      'isMobile',
      'isTablet',
      'pagesHistory'
    ]),
    prevPage () {
      if (this.pagesHistory && this.pagesHistory.length >= 2) {
        return this.pagesHistory[this.pagesHistory.length - 2]
      }
      return null
    }
  },

  watch: {
    activePage: {
      handler: function (val, oldVal) {
        if (!this.showSubMenu) {
          if (!this.goingBack) {
            this.setPageHistory({ page: val, subMenu: '' })
          } else {
            this.goingBack = false
          }
        }
      }
    },
    currentSubMenu: {
      handler: function (val, oldVal) {
        if (!this.goingBack) {
          this.setPageHistory({ page: this.activePage, subMenu: val })
        } else {
          this.goingBack = false
        }
      }
    }

  },
  created () {
    if (this.profile.questionnaireshortlink && !this.profile.questionnaire) {
      this.$router.push(`/questionnaire/${this.profile.questionnaireshortlink}`)
    }
    this.showPreferencesPrompt = false // hide this for now
    // this.showPreferencesPrompt = this.firstLogin
    // this.getEnqs()
  },
  methods: {
    ...mapActions([
      'getEnquiries',
      'popPageHistory',
      'setPageHistory',
      'setTravelInsuranceRequested'
    ]),
    // async getEnqs () {
    //   this.getEnquiries().then(enqs => {
    //   // this.myEnquiries = this.enquiries
    //     for (const enquiry of Object.values(this.enquiries)) {
    //       if (enquiry.travelInsuranceRequested === null) {
    //         this.enquiriesTravelInsurance.push(enquiry)
    //       }
    //     }
    //   })
    // },
    activeSubMenu (val) {
      this.currentSubMenu = val
      this.showSubMenu = false
    },
    changeActivePage (val) {
      this.selectMenu(getMenuItem(val))
    },
    changeSubPage (val) {
      this.currentSubMenu = val
    },
    selectMenu (item) {
      if (typeof item === 'string') {
        try {
          item = getMenuItem(item)
        } catch (error) {

        }
      }
      this.currentSubMenu = ''
      this.selectedMenuItem = item
      if (item && typeof item !== 'string') {
        this.activePage = item.value
        this.showSubMenu = item.subItems && item.subItems.length > 0
      }
    },
    // selectMenuMobile (item) {
    //   this.parentMenuValue = item
    //   // if (typeof item === 'string') {
    //   //   item = getMenuItem(item)
    //   // }
    //   // this.currentSubMenu = ''
    //   // this.selectedMenuItem = item
    //   // if (item) {
    //   //   this.activePage = item.value
    //   //   this.showSubMenu = item.subItems && item.subItems.length > 0
    //   // }
    // },
    goBack () {
      this.goingBack = true
      const prevPage = { ...this.prevPage }
      this.popPageHistory()
      this.selectMenu(prevPage.page)
      if (prevPage.subMenu) {
        this.activeSubMenu(prevPage.subMenu)
      }
    },
    goToPreferences (id, value) {
      if (value) {
        if (this.isMobile || this.isTablet) {
          this.$refs.mobileMainpage.openPage([getMenuItem('details'), getSubItem('details', 'preferences')])
          this.showMobileMenu = false
        } else {
          this.changeActivePage('details')
          this.activeSubMenu('preferences')
        }
      }
      this.showPreferencesPrompt = false
    },
    toggleModalTAndC () {
      this.showMobileMenu = false
      this.showModalTAndC = !this.showModalTAndC
    }
    // async travelInsuranceRequest (id, value) {
    //   const index = this.enquiriesTravelInsurance.findIndex(e => e.id === id)

    //   if (typeof value !== 'undefined') {
    //     await this.setTravelInsuranceRequested({ id: id, requested: value })
    //   }

    //   if (index >= 0) {
    //     this.enquiriesTravelInsurance.splice(index, 1)
    //   }
    // }
  }
}
</script>

<style scoped>
.mobile_page_content {
  min-height: calc(100vh - 240px);
}
@media (min-width: 768px) {
  .mobile_page_content {
    min-height: calc(100vh - 340px);
  }
}
</style>
