export const menuItems = [
  {
    title: 'My Journeys',
    value: 'trips',
    icons: {
      active: 'menu_myjourney_active.png',
      default: 'menu_myjourney.png'
    },
    hidden: true,
    subItems: [
      {
        title: 'All',
        value: 'all',
        hidden: false
      },
      {
        title: 'Book Tours/Activities',
        value: 'bookTours',
        icons: {
          default: 'journey_book_tours.png'
        },
        hidden: false
      },
      {
        title: 'Book Transfers',
        value: 'bookTransfers',
        icons: {
          default: 'journey_book_transfers.png'
        },
        hidden: false
      },
      {
        title: 'Book Meet & Greet',
        value: 'bookMG',
        icons: {
          default: 'journey_book_meet.png'
        },
        hidden: false
      },
      {
        title: 'Book Spa Treatment',
        value: 'bookSpa',
        icons: {
          default: 'journey_book_spa.png'
        },
        hidden: false
      },
      {
        title: 'Book a Community Tour',
        value: 'bookCommunityTour',
        icons: {
          default: 'journey_book_tours.png'
        },
        hidden: false
      },
      {
        title: 'Downloadable Travel Apps',
        value: 'downloadableTravelApps',
        icons: {
          default: 'journey_download.png'
        },
        hidden: false
      }
    ]
  },
  {
    title: 'My Information',
    value: 'details',
    icons: {
      active: 'menu_myinfo_active.png',
      default: 'menu_myinfo.png'
    },
    hidden: false,
    subItems: [
      {
        title: 'My Personal Information',
        value: 'personalDetails',
        icons: {
          default: 'info_info.png'
        },
        hidden: false
      },
      {
        title: 'My Preferences',
        value: 'preferences',
        icons: {
          default: 'info_preferences.png'
        },
        hidden: false
      },
      {
        title: 'Forms',
        value: 'forms',
        icons: {
          default: 'info_forms.png'
        },
        hidden: true
      },
      {
        title: 'About me (questionnaire)',
        value: 'aboutMe',
        hidden: true
      },
      {
        title: 'Indemnity',
        value: 'indemnity',
        hidden: true
      },
      {
        title: 'Passport details',
        value: 'passportDetails',
        icons: {
          default: 'info_forms.png'
        },
        hidden: true
      },
      {
        title: 'Travel insurance',
        value: 'travelInsurance',
        icons: {
          default: 'info_insurance.png'
        },
        hidden: true
      },
      {
        title: 'Change password',
        value: 'changePassword',
        icons: {
          default: 'info_password.png'
        },
        hidden: true
      },
      {
        title: 'Change password',
        value: 'addMember',
        icons: {
          default: 'info_member.png'
        },
        hidden: true
      }
    ]
  },
  {
    title: '',
    value: 'socialMedia',
    icons: {
    },
    hidden: true
  },
  {
    title: 'My Documents',
    value: 'docs',
    icons: {
      active: 'menu_mydocs_active.png',
      default: 'menu_mydocs.png'
    },
    hidden: false,
    subItems: [
      {
        title: 'Confirmations',
        value: 'confirmations',
        icons: {
          default: 'docs_conf_active.png'
        },
        hidden: false
      },
      {
        title: 'Itineraries',
        value: 'itineraries',
        icons: {
          default: 'docs_itin_active.png'
        },
        hidden: false
      },
      {
        title: 'eTickets',
        value: 'docETickets',
        icons: {
          default: 'docs_etickets_active.png'
        },
        hidden: true
      },
      {
        title: 'Useful information',
        value: 'docInfo',
        icons: {
          default: 'docs_info_active.png'
        },
        hidden: true
      },
      {
        title: 'Lodge information',
        value: 'lodgeInfo',
        icons: {
          default: 'docs_info_active.png'
        },
        hidden: false
      },
      {
        title: 'Additional information',
        value: 'additionalInfo',
        icons: {
          default: 'docs_info_active.png'
        },
        hidden: false
      }
    ]
  },
  {
    title: 'My Travel Planner',
    value: 'planner',
    icons: {
      active: 'menu_mytp_active.png',
      default: 'menu_mytp.png'
    },
    hidden: false
  },
  {
    title: 'Book My Next Journey',
    value: 'next',
    icons: {
      active: 'menu_booknext_active.png',
      default: 'menu_booknext.png'
    },
    hidden: true
  },
  {
    title: 'Our Contact List',
    value: 'contacts',
    icons: {
      active: 'menu_contact_active.png',
      default: 'menu_contact.png'
    },
    hidden: true
  }
]

export const getMenuItem = value => {
  const arr = menuItems.filter(mi => mi.value === value)
  if (arr.length > 0) {
    return arr[0]
  }
  throw new Error('menu.js: error in getMenuItem(value). Item not found')
}

export const getSubItem = (menuItemValue, value) => {
  const menuItem = getMenuItem(menuItemValue)
  if (menuItem.subItems && Array.isArray(menuItem.subItems)) {
    const arr = menuItem.subItems.filter(si => si.value === value)
    if (arr.length > 0) {
      return arr[0]
    }
  }
  throw new Error('menu.js: error in getSubItem(value). Item not found')
}

export const getSubItemFromList = (menuSubItems, value) => {
  if (menuSubItems && Array.isArray(menuSubItems)) {
    const arr = menuSubItems.filter(si => si.value === value)
    if (arr.length > 0) {
      return arr[0]
    }
  }
  throw new Error('menu.js: error in getSubItem(value). Item not found')
}
